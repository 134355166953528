body{
    /* Setting these variables so that if more options are added
       we can just change the variable. */
    --columns: 2;
    --rows: 2;

    margin: 0;
    padding: 0;
}

.menu{
    height: 100vh;
    width: 100vw;
}

.menu-square{
    width: calc(100vw/var(--columns));
    height: calc(100vh/var(--rows));

    font-size: calc(30vh / var(--columns));

    color: rgb(36, 35, 35);
}

.quiz-screen{
    background-color: #12161f;
    width: 100vw;
    height: 100vh;
}

.heading{
    color: #12161f;
    background-color: #87c2a6;

    font-size: 10vh;
    width: 93.4vw;
    text-align: left;

    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1vh;
    padding-bottom: 1vh;

    border: .3vw double #12161f;
    border-bottom-right-radius: 20vw;
}

.stats-table, .params{
    height: fit-content;
    width: 20vw;
    padding-top: 3vh;
    padding-bottom: 3vh;

    position: fixed;
    left: 5vw;
    top: 21vh;

    background-color: #d2c689;
    color: #12161f;
    
    border-radius: 1vw;
    border: .3vw solid #d2c689;
}

.params{
    top: 60vh;
}

.stats-heading, .params-heading{
    width: 100%;

    text-align: center;
    font-size: 5vh;
    text-decoration: underline;

    margin-bottom: 2vh;
}

.stat, .param{
    width: 100%;

    text-align: center;
    font-size: 3.8vh;
}

.param-input{
    width: 3vw;
    height: 4vh;

    font-size: 3.8vh;

    background-color: #12161f;
    color: #87c2a6;
}

.question-box{
    position: fixed;

    left: 34vw;
    right: 6vw;
    top: 21vh;
    bottom: 10vh;

    border: 1vw solid #87c2a6;
}

.question{
    --question-font-size: 15vh;

    height: 50%;
    width: 90%;

    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;

    padding-top: calc(15% - var(--question-font-size));

    font-size: var(--question-font-size);
    text-align: center;
}

.question-argument{
    color: #d2c689;
}

.operator{
    color: #87c2a6;
}

.question-input{
    height: 15vh;
    width: 15vh;

    padding: 1vh;

    font-size: 13vh;
}

.question-submit{
    height: 15vh;
    width: fit-content;

    /* padding: 1vh; */
    padding-left: 1vw;
    padding-right: 1vw;

    font-size: 8vh;
}

.nullAnswer{
    opacity: 0;
}

.correctAnswer{
    width: 20vw;
    height: 7vh;

    font-size: 6vh;
    line-height: 7vh;

    padding-top: 2vh;
    padding-bottom: 2vh;

    background-color: #87c2a6;
    color: white;

    text-align: center;

    margin-left: 18vw;
    margin-right: 19vw;
}

.incorrectAnswer{
    width: 30vw;
    height: fit-content;

    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;

    margin-left: 12vw;
    margin-right: 12vw;

    font-size: 6vh;
    line-height: 7vh;

    background-color: #f45f66;
    color: white;

    text-align: center;
}

@media only screen and (max-aspect-ratio: 1/1) {

    .menu-square{
        font-size: calc(35vw / var(--rows));
    }

    .heading{
        font-size: 10vw;
    }

    .stats-table, .params{
        left: 10vw;
        right: 10vw;
        top: 15vh;

        width: auto;
    }

    .params{
        top: 35vh;
    }

    .stats-heading, .params-heading{
        font-size: 7vw;

        margin-bottom: .5vh;
    }

    .stat, .param, .param-input{
        font-size: 4.8vw;
    }

    .param-input{
        width: 7vw;
        height: 5vw;
        text-align: center;
    }

    .question-box{
        left: 5vw;
        right: 5vw;
        top: 60vh;
        height: fit-content;

        padding-bottom: 4vh;
    }

    .question{
        --question-font-size: 10vw;

        text-align: center;
    }

    .question-input{
        height: 10vw;
        width: 15vw;

        font-size: 9vw;
    }

    .question-submit{
        height: 15vh;

        font-size: 8vw;
    }

    .correctAnswer, .incorrectAnswer{
        min-width: 40vw;
        width: fit-content;
        max-width: 70vw;

        margin-left: auto;
        margin-right: auto;

        padding-top: .5vh;
        padding-bottom: .5vh;

        font-size: 6vw;
    }

}